<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="mx-auto mt-3 lg:mt-10 md:mt-5 max-w-6xl" v-if="isLoaded">
      <!-- Operation Primer -->
      <div class="w-full mb-10" :key="`inspectionRuleSettings_${counter}`">
        <div class="flex items-center  justify-between">
          <div class="font-bold text-22px text-oCharcoal">
            <i class="fas fa-binoculars text-22px text-oPurple"></i>
            <span>
              {{
                $t(
                  'components.settingsManagement.operatorApp.headline.operationPrimerSettings.title'
                )
              }}
            </span>
          </div>
          <div
            class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end cursor-pointer"
          >
            <div v-if="!isInspectionRuleEdit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                @click="handleInspectionRuleSettingsEdit"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="{2}"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
          </div>
          <div v-if="isInspectionRuleEdit" class="flex ml-3 mt-3 mb-4">
            <anchor-button
              @click="handleInspectionRuleSettingsCancel"
              :text="$t('components.stepNavigation.cancel')"
              width="6rem"
            />
            <anchor-button
              @click="updateInspectionRuleSettings"
              :text="$t('components.stepNavigation.save')"
              variant="success"
              width="6rem"
              class="ml-4"
            />
          </div>
        </div>
        <div
          :key="`InspectionRuleSettings_${counter}`"
          class="mt-1  border-t-2"
        >
          <section>
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>
                {{
                  $t(
                    'components.settingsManagement.operatorApp.operationPrimerSettings.manageInspectionRule.title'
                  )
                }}</span
              >
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddInspectionRule"
              >
                <i class="fas fa-plus-circle" style="color: black"></i>
              </div>
            </div>

            <!-- {{ inspectionRules }} -->
            <div
              class="flex items-center mb-2"
              v-for="(item, index) in inspectionRules"
              :key="index"
            >
              <div class="flex gap-2 items-center w-11/12">
                <div class="w-7/12">
                  <AppInput
                    v-model="item.name"
                    type="richselect"
                    :name="`Name ${index}`"
                    :isInfoEnabled="false"
                    value-attribute="value"
                    text-attribute="text"
                    :placeholder="
                      $t(
                        'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.placeholder.name'
                      )
                    "
                    :options="inspectionNameOptions"
                    :disabled="!isInspectionRuleEdit"
                    :showError="false"
                    hide-search-box
                  />
                </div>

                <div class="w-1/12 flex justify-center items-center">
                  <i class="fas fa-greater-than text-22px text-oBlack"></i>
                </div>

                <div class="w-3/12">
                  <AppInput
                    v-model="item.value"
                    type="number"
                    :name="`Value ${index}`"
                    :isInfoEnabled="false"
                    rules=""
                    :haveUnitText="!!item.name"
                    :unitText="getInspectionRuleUnit(item.name)"
                    placeholder="e.g. 100"
                    :disabled="!isInspectionRuleEdit"
                    :showError="false"
                  />
                </div>
              </div>

              <div
                class="flex justify-start w-1/12 item-center"
                v-if="isInspectionRuleEdit"
              >
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveInspectionRule(index)"
                >
                  <i class="fas fa-minus-circle" style="color: #d90a20"></i>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-id-badge text-22px text-oPurple"></i>
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorGeneralSettings.title'
            )
          }}</span>
        </div>
        <p class="break-words title-description mt-1">
          {{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorGeneralSettings.description'
            )
          }}
        </p>
      </div>
      <div
        :key="`operatorGeneralSettings_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <template v-for="(item, itemIndex) in getOperatorGeneralSettings">
          <template
            v-if="
              item.variable !== 'operator_hardware_commands_allowed' &&
                item.variable !== 'should_swap_battery_for_low_level' &&
                item.variable !== 'battery_level_for_swapping'
            "
          >
            <SmartToggleInput
              v-if="item.type === 'toggle'"
              :key="itemIndex"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="general[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-else
              :key="itemIndex"
              :rowId="`operatorGeneral_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="general[`${item.variable}`]"
              @save="onSave('general', `${item.variable}`, $event)"
            />
          </template>
          <template
            v-if="
              item.variable === 'should_swap_battery_for_low_level' ||
                item.variable === 'battery_level_for_swapping'
            "
          >
            <SmartToggleInput
              v-if="item.variable === 'should_swap_battery_for_low_level'"
              :key="itemIndex"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :onText="item.onText"
              :offText="item.offText"
              :onValue="item.onValue"
              :offValue="item.offValue"
              v-model="general[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
            <SmartAppInput
              v-if="
                item.variable === 'battery_level_for_swapping' &&
                  general.should_swap_battery_for_low_level
              "
              :key="itemIndex"
              :rowId="`operatorGeneral_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              :options="item.options"
              v-model="general[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
          </template>

          <SmartToggleInput
            v-if="item.variable === 'operator_hardware_commands_allowed'"
            :key="itemIndex"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="general[`${item.variable}`]"
            @save="onSave('organization', `${item.variable}`, $event)"
          />
        </template>
      </div>

      <!-- Operator Task Wage Settings -->
      <div>
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-money-bill text-22px text-oPurple"></i>
          <span class="pl-3">{{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorTaskWage.title'
            )
          }}</span>
        </div>
        <p class="break-words title-description mt-1">
          {{
            $t(
              'components.settingsManagement.operatorApp.headline.operatorTaskWage.description'
            )
          }}
        </p>
      </div>
      <div
        :key="`operatorWageSettings_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <template v-for="(item, itemIndex) in operatorWageSettings">
          <SmartAppInput
            v-if="itemIndex <= 1"
            :key="itemIndex"
            :rowId="`operatorWage_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="wage.charging[`${item.variable}`]"
            @save="onSave('charging', `${item.variable}`, $event)"
          />
          <SmartAppInput
            v-else
            :key="itemIndex"
            :rowId="`operatorWage_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :placeholder="item.placeholder"
            :description="item.description"
            :isEdit="item.isEdit"
            v-model="wage.rebalancing[`${item.variable}`]"
            @save="onSave('rebalancing', `${item.variable}`, $event)"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import AnchorButton from '@/components/form/AnchorButton'
import { EventBus } from '@/utils'
import {
  OperatorSettingsConfig,
  VehicleSettingsConfig,
} from '@/config/SettingsConfig'
import { useEndpoints } from '@/composables'
// import _ from 'lodash'
export default {
  name: 'OperatorSettings',
  components: {
    SmartAppInput,
    SmartToggleInput,
    AnchorButton,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      isInspectionRuleEdit: false,
      inspectionRules: [],
      previousInspectionRules: [],
      inspectionNameOptions: [
        {
          text: this.$t(
            'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.ruleoptions.VDGT'
          ),
          value: 'VDGT',
        },
        {
          text: this.$t(
            'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.ruleoptions.TTGT'
          ),
          value: 'TTGT',
        },
        {
          text: this.$t(
            'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.ruleoptions.DPALIGT'
          ),
          value: 'DPALIGT',
        },
      ],
      toDeleteInspectionRules: [],

      dataChangeStatus: false,
      general: {
        drop_off_photo_required: true,
        check_inside_drop_off_area: true,
        is_reservation_required: true,
        release_spot_reservation_time_in_min: 0,
        operator_hardware_commands_allowed: true,
        should_swap_battery_for_low_level: false,
        battery_level_for_swapping: 0,
        should_unlock_vehicle_on_pickup: false,
        should_lock_vehicle_on_dropoff: false,
        should_allow_non_assignee_to_drop_vehicles: false,
      },

      wage: {
        charging: {
          rate: '',
          point: '',
        },
        rebalancing: {
          rate: '',
          point: '',
        },
      },
      operatorGeneralSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.requireDropOffPhoto.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.requireDropOffPhoto.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'drop_off_photo_required',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.validateInsideDropOffArea.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.validateInsideDropOffArea.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'check_inside_drop_off_area',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.reservationRequirement.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.reservationRequirement.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_reservation_required',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.releaseSpotReservationTimeoutMinutes.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.releaseSpotReservationTimeoutMinutes.description'
          ),
          variable: 'release_spot_reservation_time_in_min',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.allowOperatorHardwareCommands.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.allowOperatorHardwareCommands.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'operator_hardware_commands_allowed',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.triggerSwapBatteryNeededStatusBasedOnLowBattery.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.triggerSwapBatteryNeededStatusBasedOnLowBattery.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_swap_battery_for_low_level',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.batteryLevelForSwapping.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.batteryLevelForSwapping.description'
          ),
          variable: 'battery_level_for_swapping',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.unlockVehicleBeforePickingUp.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.unlockVehicleBeforePickingUp.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_unlock_vehicle_on_pickup',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.lockVehicleAfterDroppingOff.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.lockVehicleAfterDroppingOff.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_lock_vehicle_on_dropoff',
          isEdit: false,
        },
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.allowNonassigneeToDropVehicles.title'
          ),
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorGeneralSettings.allowNonassigneeToDropVehicles.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'should_allow_non_assignee_to_drop_vehicles',
          isEdit: false,
        },
      ],
      operatorWageSettings: [
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForChargingTask.title'
          ),

          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForChargingTask.description'
          ),
          variable: 'rate',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForChargingTask.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForChargingTask.description'
          ),
          variable: 'point',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForRebalancingTask.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.earningRateForRebalancingTask.description'
          ),
          variable: 'rate',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForRebalancingTask.title'
          ),
          placeholder: `e.g 5`,
          description: this.$t(
            'components.settingsManagement.operatorApp.operatorWageSettings.pointValueForRebalancingTask.description'
          ),
          variable: 'point',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getOperatorGeneralSettings() {
      const allOperatorGeneralSettings = this.operatorGeneralSettings
      if (this.general.is_reservation_required === false) {
        const removeSettingsKey = ['release_spot_reservation_time_in_min']
        const filteredSettings = allOperatorGeneralSettings.filter(
          (item) => !removeSettingsKey.includes(item.variable)
        )
        return filteredSettings
      } else {
        return this.operatorGeneralSettings
      }
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'inspectionRuleSettings') {
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
        if (val === 'operatorGeneralSettings') {
          this.handleInspectionRuleSettingsCancel()
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'operatorWageSettings') {
          this.handleInspectionRuleSettingsCancel()
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
        }
      },
    },
    inspectionRules: {
      deep: true,
      immediate: false,
      handler(updatedData) {
        this.dataChangeStatus = this.haveAnyDataDiffForInspectionRule(
          this.previousInspectionRules,
          updatedData
        )
      },
    },
  },
  async created() {
    const reqCharging = this.$http.get(
      OperatorSettingsConfig.api.charging.index(this.orgId)
    )
    const reqRebalancing = this.$http.get(
      OperatorSettingsConfig.api.rebalancing.index(this.orgId)
    )
    const reqGeneral = this.$http.get(
      OperatorSettingsConfig.api.general.index(this.orgId)
    )

    const orgReq = this.$http.get(useEndpoints.organization.details(this.orgId))

    const inspectionRuleReq = this.$http.get(
      useEndpoints.operationPrimer.inspectionRules.index(this.orgId)
    )
    const vehicleConfigReq = this.$http.get(
      VehicleSettingsConfig.api.index(this.orgId)
    )

    const requests = [
      reqCharging,
      reqRebalancing,
      reqGeneral,
      orgReq,
      inspectionRuleReq,
      vehicleConfigReq,
    ]

    try {
      const responses = await Promise.allSettled(requests)
      responses.map((response) => {
        if (response.status === 'fulfilled') {
          if (
            response.value.config.url ===
            OperatorSettingsConfig.api.charging.index(this.orgId)
          ) {
            this.wage.charging.rate = response.value.data.rate
            this.wage.charging.point = response.value.data.point
          }
          if (
            response.value.config.url ===
            OperatorSettingsConfig.api.rebalancing.index(this.orgId)
          ) {
            this.wage.rebalancing.rate = response.value.data.rate
            this.wage.rebalancing.point = response.value.data.point
          }
          if (
            response.value.config.url ===
            OperatorSettingsConfig.api.general.index(this.orgId)
          ) {
            this.general.drop_off_photo_required =
              response.value.data.drop_off_photo_required
            this.general.is_reservation_required =
              response.value.data.is_reservation_required
            this.general.check_inside_drop_off_area =
              response.value.data.check_inside_drop_off_area
            this.general.release_spot_reservation_time_in_min =
              response.value.data.release_spot_reservation_time_in_min

            this.general.should_unlock_vehicle_on_pickup =
              response.value.data.should_unlock_vehicle_on_pickup

            this.general.should_lock_vehicle_on_dropoff =
              response.value.data.should_lock_vehicle_on_dropoff

            this.general.should_allow_non_assignee_to_drop_vehicles =
              response.value.data.should_allow_non_assignee_to_drop_vehicles
          }
          if (
            response.value.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            this.general.operator_hardware_commands_allowed =
              response.value.data.operator_hardware_commands_allowed
          }
          if (
            response.value.config.url ===
            useEndpoints.operationPrimer.inspectionRules.index(this.orgId)
          ) {
            this.inspectionRules = this.getFormattedInspectionRulesData(
              response.value.data.data
            )
            this.previousInspectionRules = this.getFormattedInspectionRulesData(
              response.value.data.data
            )
          }
          if (
            response.value.config.url ===
            VehicleSettingsConfig.api.index(this.orgId)
          ) {
            this.general.should_swap_battery_for_low_level =
              response.value.data.should_swap_battery_for_low_level
            this.general.battery_level_for_swapping =
              response.value.data.battery_level_for_swapping
          }
        }
        if (response.status === 'rejected') {
          let message = ''
          if (
            response.reason.config.url ===
            OperatorSettingsConfig.api.charging.index(this.orgId)
          ) {
            message = `Operator Task Charging Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            OperatorSettingsConfig.api.rebalancing.index(this.orgId)
          ) {
            message = `Operator Task Rebalancing Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            OperatorSettingsConfig.api.general.index(this.orgId)
          ) {
            message = `Operator Task General Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.organization.details(this.orgId)
          ) {
            message = `Org Details: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            useEndpoints.operationPrimer.inspectionRules.index(this.orgId)
          ) {
            message = `Inspection Rules: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            VehicleSettingsConfig.api.index(this.orgId)
          ) {
            message = `Vehicle Settings: ${response.reason.response.data.detail}`
          }

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Error [${response.reason.response.status}]`,
              text: message,
            },
            5000
          )
        }
      })
    } catch (err) {
      console.log('Error loading data', { err })
    } finally {
      this.isLoaded = true
    }
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'operatorGeneral') {
        this.operatorGeneralSettings = this.operatorGeneralSettings.map(
          (item, itemIndex) => {
            if (id === `operatorGeneral_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'operatorGeneralSettings'
      }
      if (idPrefix === 'operatorWage') {
        this.operatorWageSettings = this.operatorWageSettings.map(
          (item, itemIndex) => {
            if (id === `operatorWage_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'operatorWageSettings'
      }
    })
  },
  methods: {
    getFormattedInspectionRulesData(response) {
      return response.map((element) => {
        const rule = {
          id: element.id,
          name: element.name,
        }
        if (element.name === 'VDGT') {
          rule['value'] = element.rule_fields.distance_travelled_threshold
        }
        if (element.name === 'TTGT') {
          rule['value'] = element.rule_fields.trips_taken_threshold
        }
        if (element.name === 'DPALIGT') {
          rule['value'] = element.rule_fields.days_passed_threshold
        }
        return rule
      })
    },
    haveAnyDataDiffForInspectionRule(previousData, currentData) {
      if (previousData.length !== currentData.length) {
        return true
      }
      for (let i = 0; i < previousData.length; i++) {
        if (previousData[i].name !== currentData[i].name) {
          return true
        }
        if (Number(previousData[i].value) !== Number(currentData[i].value)) {
          return true
        }
      }
      // If no differences found
      return false
    },
    getInspectionRuleUnit(name) {
      if (name === 'VDGT') {
        return this.$t(
          'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.ruleoptionsUnit.meter'
        )
      }
      if (name === 'TTGT') {
        return this.$t(
          'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.ruleoptionsUnit.trips'
        )
      }
      if (name === 'DPALIGT') {
        return this.$t(
          'components.settingsManagement.operatorApp.operationPrimerSettings.inspectionRule.ruleoptionsUnit.days'
        )
      }
    },
    handleInspectionRuleSettingsEdit() {
      this.isInspectionRuleEdit = true
      this.currentSettingsGroup = 'inspectionRuleSettings'
    },
    handleInspectionRuleSettingsCancel() {
      this.isInspectionRuleEdit = false
      if (this.dataChangeStatus) {
        this.inspectionRules = [...this.previousInspectionRules]
        this.dataChangeStatus = false
      }
    },
    async updateInspectionRuleSettings() {
      let inspectionRuleRequests = []
      if (this.inspectionRules.length > 0) {
        this.inspectionRules.forEach((element) => {
          const isUpdateReq = 'id' in element
          const inspectionRuleMethod = isUpdateReq ? 'PATCH' : 'POST'
          const inspectionRuleUrl = isUpdateReq
            ? useEndpoints.operationPrimer.inspectionRules.update(element.id)
            : useEndpoints.operationPrimer.inspectionRules.create(this.orgId)

          const inspectionRuleData = new FormData()
          inspectionRuleData.append('name', element.name)

          if (element.value) {
            if (`${element.value}`.trim() === '' || !isNaN(element.value)) {
              if (element.name === 'VDGT') {
                inspectionRuleData.append(
                  'distance_travelled_threshold',
                  element.value
                )
              }
              if (element.name === 'TTGT') {
                inspectionRuleData.append(
                  'trips_taken_threshold',
                  element.value
                )
              }
              if (element.name === 'DPALIGT') {
                inspectionRuleData.append(
                  'days_passed_threshold',
                  element.value
                )
              }
            }
          }

          const inspectionRuleReq = this.$http({
            url: inspectionRuleUrl,
            method: inspectionRuleMethod,
            data: inspectionRuleData,
          })
          inspectionRuleRequests.push(inspectionRuleReq)
        })
      }

      if (
        this.isInspectionRuleEdit &&
        this.toDeleteInspectionRules.length > 0
      ) {
        this.toDeleteInspectionRules.forEach((element) => {
          if ('id' in element) {
            const inspectionRuleReq = this.$http({
              url: useEndpoints.operationPrimer.inspectionRules.update(
                element.id
              ),
              method: 'DELETE',
            })
            inspectionRuleRequests.push(inspectionRuleReq)
          }
        })
      }

      const requests = [...inspectionRuleRequests]

      this.isLoading = true
      await this.$http
        .all(requests)
        .then(() => {
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: 'Updated Successfully',
          })
        })
        .catch((err) => {
          console.error('Error occurred!', err)
          this.$notify({
            group: 'generic',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.message,
          })
        })
        .finally(() => {
          this.isInspectionRuleEdit = false
          this.toDeleteInspectionRules = []
          this.$http
            .get(useEndpoints.operationPrimer.inspectionRules.index(this.orgId))
            .then((res) => {
              this.inspectionRules = this.getFormattedInspectionRulesData(
                res.data.data
              )
              this.previousInspectionRules = this.getFormattedInspectionRulesData(
                res.data.data
              )
            })
        })
    },
    onAddInspectionRule() {
      if (
        this.isInspectionRuleEdit &&
        this.inspectionRules.length === this.inspectionNameOptions.length
      ) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Warning',
            text: 'You have created all inspection rules already',
          },
          5000
        )
        return
      }
      if (this.isInspectionRuleEdit) {
        this.inspectionRules.unshift({ name: '', value: '' })
      }
    },
    onRemoveInspectionRule(index) {
      if (this.isInspectionRuleEdit) {
        let toRemoveInspectionRule = this.inspectionRules[index]
        if (
          this.inspectionRules.filter(
            (entry) => entry.name === toRemoveInspectionRule.name
          ).length > 0
        ) {
          this.toDeleteInspectionRules.push(this.inspectionRules[index])
        }

        this.inspectionRules.splice(index, 1)
      }
    },
    async onSave(group, key, val) {
      let toastTitle, toastText

      if (key === 'drop_off_photo_required') {
        toastTitle = `Drop-off Photo Required`
        toastText = `Drop-off Photo Required has been updated`
      }
      if (key === 'check_inside_drop_off_area') {
        toastTitle = `Check Inside Drop-off Area`
        toastText = `Check Inside Drop-off Area has been updated`
      }
      if (key === 'is_reservation_required') {
        toastTitle = `Reservation Required`
        toastText = `Reservation Required has been updated`
      }
      if (key === 'should_swap_battery_for_low_level') {
        toastTitle = `Successful`
        toastText = `Update Successfully`
      }
      if (key === 'battery_level_for_swapping') {
        toastTitle = `Successful`
        toastText = `Update Successfully`
      }
      if (key === 'release_spot_reservation_time_in_min') {
        toastTitle = `Release Spot Reservation Timeout (Minutes)`
        toastText = `Release Spot Reservation Timeout (Minutes) has been updated`
      }
      if (key === 'should_unlock_vehicle_on_pickup') {
        toastTitle = `Successful`
        toastText = `Update Successfully`
      }
      if (key === 'should_lock_vehicle_on_dropoff') {
        toastTitle = `Successful`
        toastText = `Update Successfully`
      }
      if (key === 'should_allow_non_assignee_to_drop_vehicles') {
        toastTitle = `Successful`
        toastText = `Update Successfully`
      }
      if (group === 'charging' && key === 'rate') {
        toastTitle = `Earning Rate (Charging)`
        toastText = `Earning Rate (Charging) has been updated`
      }
      if (group === 'charging' && key === 'point') {
        toastTitle = `Point (Charging)`
        toastText = `Point (Charging) has been updated`
      }
      if (group === 'rebalancing' && key === 'rate') {
        toastTitle = `Earning Rate (Rebalance)`
        toastText = `Earning Rate (Rebalance) has been updated`
      }
      if (group === 'rebalancing' && key === 'point') {
        toastTitle = `Point (Rebalance)`
        toastText = `Point (Rebalance) has been updated`
      }

      if (
        group === 'organization' &&
        key === 'operator_hardware_commands_allowed'
      ) {
        toastTitle = `Operator hardware commands allowed`
        toastText = `Operator hardware commands allowed has been updated`
      }

      let data = new FormData()
      data.append(key, val)
      let reqLink = null
      if (group === 'general') {
        reqLink = OperatorSettingsConfig.api.general.index(this.orgId)
      }
      if (group === 'charging') {
        reqLink = OperatorSettingsConfig.api.charging.index(this.orgId)
      }
      if (group === 'rebalancing') {
        reqLink = OperatorSettingsConfig.api.rebalancing.index(this.orgId)
      }
      if (group === 'organization') {
        reqLink = useEndpoints.organization.details(this.orgId)
      }
      if (group === 'vehicle') {
        reqLink = VehicleSettingsConfig.api.update(this.orgId)
      }
      await this.$http
        .patch(reqLink, data)
        .then(() => {
          this.operatorGeneralSettings = this.operatorGeneralSettings.map(
            (item) => ({
              ...item,
              isEdit: false,
            })
          )
          this.operatorWageSettings = this.operatorWageSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          console.log('onSaveError', err)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.title-description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63636c !important;
}
</style>
